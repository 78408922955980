


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@service/contact.service";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { ColumnTableCustom } from "@interface/util.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import {
  formatTaxNumber,
  formatTaxNumbertoNumber,
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import {
  DataCoa,
  DataListRelatedCode,
  DataMasterCurrency,
  ResponseListOfTaxType,
} from "@/models/interface/settings.interface";
import moment, { Moment } from "moment";
import { invoicePrepayment } from "@service/invoice-prepayment.service";
import { CreateInvoicePrepayment, ResponseCreateInvoicePrepayment } from "@interface/invoice-prepayment.interface";
import printJs from "print-js";
import { accountingTaxService } from "@/services-v2/accounting-tax.service";
import { ResponseAccountingTax } from "@/models/interface-v2/accounting-tax.interface";
import { Mode } from "@/models/enums/global.enum";
import MNotificationVue from "@/mixins/MNotification.vue";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Decimal } from "decimal.js-light";
import { IOption } from "@/models/interface-v2/common.interface";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";

interface DefaultDropdown {
  id: string;
  name: string;
  idCardNumber?: "";
}
@Component({
  name: "CreateInvoicePrepayment",
  mixins: [
    MNotificationVue,
  ]
})
export default class CreateInvoicePrepaymentComponent extends Vue {
  formatTax = formatTaxNumber;
  formatTaxtoNumber = formatTaxNumbertoNumber;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  dataListTaxInvoicecode = [] as DataListRelatedCode[];
  journalNumber = {
    name: "" as string,
    id: "" as string,
  };
  checkMandatory = 0 as number;
  form!: WrappedFormUtils;
  formTable!: WrappedFormUtils;
  formTotal!: WrappedFormUtils;
  page = 1 as number;
  limit = 10 as number;
  columnTabPane = [] as string[];
  activeTabPane = "Details" as string;
  modalOpen = false as boolean;
  dataListBranch = [] as DataWarehouseBranch[];
  dataListCurrency = [] as DataMasterCurrency[];
  dataListReceivableAccount = [] as DataCoa[];
  customerId = "" as string;
  // showSalesOrder = true as boolean;
  disabled = {
    submit: true as boolean,
    cancel: true as boolean,
    invoiceType: false as boolean,
    customerName: false as boolean,
    customerShipTo: false as boolean,
    customerBillTo: false as boolean,
    receivableAccount: false as boolean,
    branch: false as boolean,
    currency: false as boolean,
    invoiceNumber: true as boolean,
    operatorName: false as boolean,
    invoiceDate: false as boolean,
    accountingDate: false as boolean,
    journalNumber: false as boolean,
    invoiceDescription: false as boolean,
    tabpan: true as boolean,
    rate: false as boolean,
    print: true as boolean,
    returnNumber: true as boolean,
    // salesOrder: false as boolean,
  };
  loading = {
    invoiceType: false as boolean,
    customerName: false as boolean,
    customerShipTo: false as boolean,
    customerBillTo: false as boolean,
    receivableAccount: false as boolean,
    branch: false as boolean,
    currency: false as boolean,
    submit: false as boolean,
    cancel: false as boolean,
    print: false as boolean,
    // salesOrder: false as boolean,
  };
  columnsTableModal = [] as ColumnTableCustom[];
  dataListItemsModal = [] as any[];
  loadingTable = {
    termOfPayments: false as boolean,
    taxType: false as boolean,
    customerTax: false as boolean,
    paidStatus: false as boolean,
    taxCode: false as boolean,
  };
  disabledTable = {
    amount: false as boolean,
    poNumber: false as boolean,
    termOfPayments: false as boolean,
    taxType: false as boolean,
    customerTaxType: false as boolean,
    taxInvoiceDate: false as boolean,
    paidStatus: false as boolean,
    taxRegistrationName: false as boolean,
    taxRegistrationNumber: false as boolean,
    taxCode: false as boolean,
  };
  typeDetail = "" as string;
  currencyRate = 1 as number;
  mode = "" as string;
  customerTaxType = "" as string;
  dataListInvoiceType = [] as DefaultDropdown[];
  dataListCustomer = [] as any[];
  dataListCustomerShipTo = [] as DefaultDropdown[];
  dataListCustomerBillTo = [] as DefaultDropdown[];
  typeCurrency = "" as string;
  dataListTaxType = [] as ResponseListOfTaxType[];
  // dataListSalesOrderNumber = [] as SalesOrderData[];
  dataListTaxcode = [] as ResponseAccountingTax[];
  valueRadio = "" as any;
  checkOnBlur = 0 as number;
  dataDetailStatus = {
    invoiceARDetails: [] as any[],
    invoiceARReceiptDetails: [] as any[],
  };
  show = {
    returnNumber: false as boolean,
  };
  formRules = {
    invoiceType: {
      label: "lbl_invoice_type",
      name: "invoiceType",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceType",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    // salesOrder: {
    //   label: "Sales Order",
    //   name: "salesOrder",
    //   placeholder: "Insert Sales Order",
    //   decorator: [
    //     "salesOrder",
    //     {
    //       rules: [
    //         {
    //           required: false,
    //                         message:this.$t(Messages.VALIDATION_REQUIRED_ERROR),

    //         },
    //       ],
    //     },
    //   ],
    // },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceNumber",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    returnNumber: {
      label: "lbl_return_number",
      name: "returnNumber",
      placeholder: "lbl_choose",
      decorator: [
        "returnNumber",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_choose",
      decorator: [
        "customerName",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    customerShipTo: {
      label: "lbl_customer_shipping_address",
      name: "customerShipTo",
      placeholder: "lbl_choose",
      decorator: [
        "customerShipTo",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    customerBillTo: {
      label: "lbl_customer_bill_address",
      name: "customerBillTo",
      placeholder: "lbl_choose",
      decorator: [
        "customerBillTo",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    receivableAccount: {
      label: "lbl_receivable_account",
      name: "receivableAccount",
      placeholder: "lbl_choose",
      decorator: [
        "receivableAccount",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    operatorName: {
      label: "lbl_operator_name",
      name: "operatorName",
      placeholder: "lbl_type_here",
      decorator: [
        "operatorName",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_choose",
      decorator: [
        "branch",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_choose",
      decorator: [
        "currency",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_type_here",
      decorator: [
        "rate",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_choose",
      decorator: [
        "invoiceDate",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    accountingDate: {
      label: "lbl_accounting_date",
      name: "accountingDate",
      placeholder: "lbl_choose",
      decorator: [
        "accountingDate",
        {
          initialValue: undefined,
          rules: [
            {
              required: true,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_choose",
      decorator: [
        "journalNumber",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    invoiceDescription: {
      label: "lbl_description",
      name: "invoiceDescription",
      placeholder: "lbl_type_here",
      decorator: [
        "invoiceDescription",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    subTotal: {
      label: "lbl_sub_total",
      name: "subTotal",
      placeholder: "lbl_type_here",
      decorator: [
        "subTotal",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    totalTax: {
      label: "lbl_total_tax",
      name: "totalTax",
      placeholder: "lbl_type_here",
      decorator: [
        "totalTax",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_type_here",
      decorator: [
        "total",
        {
          initialValue: undefined,
          rules: [
            {
              required: false,
              message: (): string => this.$t("lbl_validation_required_error").toString(),
            },
          ],
        },
      ],
    },
  };
  dtDetailInvoicePrepayment = {} as ResponseCreateInvoicePrepayment

  @Watch("checkOnBlur")
  public blurChangeData(data): void {
    if (data > 0) {
      this.formTotal.setFieldsValue({
        total: currencyFormat(
          changeCurrencytoNumeric(this.formTotal.getFieldValue("subTotal")) +
          changeCurrencytoNumeric(this.formTotal.getFieldValue("totalTax"))
        ),
      });
    }
  }
  @Watch("checkMandatory")
  public changeMandatory(data) {
    if (data > 0) {
      if (
        this.formTable.getFieldValue("amount") &&
        this.formTable.getFieldValue("termOfPayment") &&
        this.formTable.getFieldValue("lineAccount") &&
        this.formTable.getFieldValue("taxType") &&
        this.formTable.getFieldValue("taxCode") &&
        this.formTable.getFieldValue("customerTaxType") &&
        this.formTable.getFieldValue("taxRegistrationNumber") &&
        this.formTable.getFieldValue("taxRegistrationName") &&
        this.formTable.getFieldValue("taxInvoiceDate")
      ) {
          if (this.typeCurrency === "IDR") {
            const invoiceDate = this.form.getFieldValue("invoiceDate").format(DEFAULT_DATE_FORMAT);
            if (
              this.form.getFieldValue("invoiceType") &&
              this.form.getFieldValue("customerName") &&
              this.form.getFieldValue("branch") &&
              this.form.getFieldValue("currency") &&
              this.form.getFieldValue("receivableAccount") &&
              invoiceDate
            ) {
              this.disabled.submit = false;
            } else {
              this.disabled.submit = true;
            }
              
          } else {
            if (
              this.form.getFieldValue("receivableAccount") &&
              this.form.getFieldValue("rate")
            ) {
              this.disabled.submit = false;
            } else {
              this.disabled.submit = true;
            }
          }
        } else {
          this.disabled.submit = true;
        }
    } else {
      this.disabled.submit = true;
    }
  }
  setTotalTax(): void {
    if (this.formTable.getFieldValue("taxType") === "Exclusive") {
      const tax = this.dataListTaxcode.find(x => x.id === this.formTable.getFieldValue("taxCode"));
      if (!tax) return;
      const subtotal = new Decimal(changeCurrencytoNumeric(this.formTotal.getFieldValue("subTotal") || 0));
      const taxRate = new Decimal(tax.rate || 0).dividedBy(100);
      const totalTax = subtotal.times(taxRate).toNumber();
      this.formTotal.setFieldsValue({
        totalTax: currencyFormat(totalTax)
      });
    } else {
      const amount = new Decimal(changeCurrencytoNumeric(this.formTable.getFieldValue("amount") || 0));
      const subtotal = new Decimal(changeCurrencytoNumeric(this.formTotal.getFieldValue("subTotal") || 0));
      this.formTotal.setFieldsValue({
        totalTax: currencyFormat(amount.minus(subtotal).toNumber()),
      });
    }
  }
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "createInvoicePrepayment" });
    this.formTable = this.$form.createForm(this, { name: "tableInvoicePrepayment" });
    this.formTotal = this.$form.createForm(this, {
      name: "tableTotalInvoicePrepayment",
    });
  }

  created(): void {
    this.mode = this.$route.meta.mode;
  }

  mounted(): void {
    this.getListCustomer("", "");
    this.getListOfWarehouseBranch("", "");
    this.getListOfMasterCurrency("", "");
    this.getListOfMasterTypeInvoiceType();
    this.getListOfMasterType();
    this.getListTaxCode();
    if (this.mode === Mode.CREATE) {
      this.getListOfTax("taxType~VAT_OUT", "init");
      this.getListOfPreference(
        "search=key~*account_setup_prepayment_ar*",
        "",
        "",
        false
      );
      this.getListOfPreference(
        "search=key~*feature_base_currency*",
        "",
        "",
        "null"
      );
      this.columnTabPane = ["Details", "Tax Details"];
      this.disabled.cancel = true;
      // this.showSalesOrder = true;
      this.setDefault();
    } else {
      this.getDetailInvoicePrepayment(this.$route.params.id);
      this.disabled.cancel = false;
      this.disabled.print = false;
      this.columnTabPane = ["Details", "Tax Details", "Status"];
    }
  }

  get getUsername(): string {
    return this.$store.getters["authStore/GET_USERNAME"];
  }

  get hasPrivilegeUpdate(): boolean {
    return this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "invoice-prepayment" && x.privilege.update && x.privilege.create);
  }

  @Watch("getUsername")
  onChangeUsername(): void {
    if (this.mode === Mode.CREATE) {
      this.setDefault();
    }
  }

  setDefault(): void {
    this.form.setFieldsValue({
      operatorName: this.getUsername,
    });
  }

  getListOfTax(query, value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (query && value) params.search = query;
    this.loadingTable.taxCode = true;
    return accountingTaxService
      .listOfTax(params)
      .then((res) => {
        this.dataListTaxcode = res.data;
        this.formTable.setFieldsValue({
          taxCode: this.dataListTaxcode.find(
            (item) => item.rate === 0
          )?.id,
        });
      })
      .finally(() => (this.loadingTable.taxCode = false));
  }
  onchangeFormMandatory(): void {
    this.checkMandatory += 1;
  }
  changeTaxCode(e: string): void {
    this.formTable.setFieldsValue({
      taxCode: e
    });
    this.setSubtotal(
      changeCurrencytoNumeric(this.formTable.getFieldValue("amount"))
    );
    this.setTotalTax();
    this.checkOnBlur += 1;
    this.onchangeFormMandatory();
  }
  clickJournal(id: string): void {
    if (id) this.$router.push("/generaljournal/journal/detail/" + id);
  }
  changeTaxType(e): void {
    this.formTable.setFieldsValue({
      taxType: e
    });
    this.disabledTable.taxCode = e === TAX_CALCULATION.NONE;
    if (e === TAX_CALCULATION.NONE) {
      this.formTable.setFieldsValue({
        taxCode: this.dataListTaxcode.find(
          (item) => item.rate === 0
        )?.id,
      });
    }
    this.setSubtotal(
      changeCurrencytoNumeric(this.formTable.getFieldValue("amount"))
    );
    this.setTotalTax();
    this.checkOnBlur += 1;
    this.onchangeFormMandatory();
  }
  getDetailInvoicePrepayment(id: string): void {
    invoicePrepayment.detailInvoicePrepayment(id).then((res) => {
      this.dtDetailInvoicePrepayment = res;
      this.checkDropdown(res, true);
      // if (res.salesOrderId) this.showSalesOrder = true;
      // else this.showSalesOrder = false;
      this.disabled.tabpan = false;
      this.disabled.submit = true;
      this.disabled.cancel = false;
      this.disabled.operatorName = true;
      this.disabled.invoiceDate = true;
      this.disabled.accountingDate = true;
      this.disabled.invoiceDescription = true;
      this.disabled.rate = true;
      this.disabled.customerName = true;
      this.disabled.customerShipTo = true;
      this.disabled.customerBillTo = true;
      this.disabled.branch = true;
      this.disabled.currency = true;
      this.disabled.receivableAccount = true;
      this.disabled.invoiceType = true;
      // this.disabled.salesOrder = true;
      this.disabledTable.amount = true;
      this.disabledTable.poNumber = true;
      this.disabledTable.termOfPayments = true;
      this.disabledTable.taxType = true;
      this.disabledTable.customerTaxType = true;
      this.disabledTable.taxRegistrationNumber = true;
      this.disabledTable.taxRegistrationName = true;
      this.disabledTable.taxInvoiceDate = true;
      this.disabledTable.paidStatus = true;
      this.disabledTable.taxCode = true;
      this.dataDetailStatus.invoiceARDetails = res.invoiceARDetails || [];
      this.dataDetailStatus.invoiceARReceiptDetails =
        res.invoiceARReceiptDetails || [];
      this.journalNumber.name = res.journalNumber;
      this.journalNumber.id = res.journalId;
      this.form.setFieldsValue({
        invoiceType: res.invoiceType,
        // salesOrder: res.salesOrderId,
        customerName: res.customerId,
        customerShipTo: res.customerShipToAddress,
        customerBillTo: res.customerBillToAddress,
        invoiceNumber: res.invoicePrepaymentNumber,
        operatorName: res.operatorName,
        branch: res.branchId,
        currency: res.currency,
        receivableAccount: res.receivableAccountId,
        invoiceDate: moment(res.invoiceDate).format(),
        accountingDate: moment(res.accountingDate).format(),
        invoiceDescription: res.description,
      });
      if (res.invoiceType === "Credit Memo") {
        this.show.returnNumber = true;
        this.form.setFieldsValue({
          returnNumber: res.returnNumber,
        });
      } else {
        this.show.returnNumber = false;
      }
      this.formTable.setFieldsValue({
        amount: currencyFormat(res.amount),
        poNumber: res.poNumber,
        termOfPayment: res.termOfPayment.toString(),
        lineAccount: res.lineAccount,
        taxType: res.taxType,
        taxCode: res.taxCode,
        customerTaxType: res.customerTaxType,
        taxRegistrationNumber: res.taxRegistrationNumber,
        taxRegistrationName: res.taxRegistrationName,
        taxInvoiceDate: moment(res.taxInvoiceDate).format(),
        taxInvoiceNumber: res.taxInvoiceNumber,
        total: res.total ? currencyFormat(res.total) : "",
        prepaymentUsed: res.prepaymentUsed,
        remainingPrepayment: res.remainingPrepayment
          ? currencyFormat(res.remainingPrepayment)
          : "0.00",
        prepaymentPaid: res.prepaymentPaid,
        paidStatus: res.invoicePrepaymentStatus,
      });
      this.formTotal.setFieldsValue({
        subTotal: res.subTotal ? currencyFormat(res.subTotal) : "0.00",
        totalTax: res.totalTax ? currencyFormat(res.totalTax) : "0.00",
        total: res.total ? currencyFormat(res.total) : "0.00",
      });
    });
  }
  changeAmount(value) {
    this.formTable.setFieldsValue({
      amount: value.target.value ? currencyFormat(value.target.value) : "0.00",
    });
    if (changeCurrencytoNumeric(value.target.value)) {
      this.setSubtotal(changeCurrencytoNumeric(value.target.value));
      this.setTotalTax();
    } else {
      this.formTotal.setFieldsValue({
        subTotal: "",
        total: "",
        totalTax: "",
      });
    }
    this.checkOnBlur += 1;
    this.onchangeFormMandatory();
  }

  /**
   * @param value amount
   */
  setSubtotal(value): void {
    if (this.formTable.getFieldValue("taxType") === "Exclusive") {
      this.formTotal.setFieldsValue({
        subTotal: currencyFormat(value),
      });
    } else {
      const tax = this.dataListTaxcode.find(x => x.id === this.formTable.getFieldValue("taxCode"));
      if (!tax) return;
      const taxRate = new Decimal(tax.rate || 0).dividedBy(100).plus(1);
      const subTotal = new Decimal(value || 0).dividedBy(taxRate).toNumber();
      this.formTotal.setFieldsValue({
        subTotal: currencyFormat(subTotal),
      });
    }
  }
  changeInvoiceDate(value: Moment): void {
    this.form.setFieldsValue({
      accountingDate: value,
    });
    this.formTable.setFieldsValue({
      taxInvoiceDate: value,
    });
    this.onchangeFormMandatory();
  }
  changeCustomerTax(value) {
    if (value) {
      this.getTaxInvoiceNumber(value);
    } else {
      this.formTable.setFieldsValue({
        taxInvoiceNumber: "",
      });
    }
    this.onchangeFormMandatory();
  }

  /**
   * @param value currency secureId
   */
  changeCurrency(value: string): void {
    this.onchangeFormMandatory();

    /**
     * find currency code based on
     * current currency list
     * type currency is used by field rate
     */
    const code = this.dataListCurrency.find(x => x.id === value)?.currencyCode || "";
    this.typeCurrency = code || "";

    if (!value) return;
    if (code === "IDR") {
      this.currencyRate = 1;
      this.getListOfPreference(
        "search=key~*account_setup_account_receiviables*",
        code,
        code,
        true
      );
      /**
       * set mandatory field
       * currency rate
       */
      // this.formRules.rate.decorator[1].rules[0].required = false;
      this.form.getFieldDecorator("rate", {
        rules: [{ required: false }]
      });
      
    } else {
      this.form.getFieldDecorator("rate", {
        rules: [{ required: true }]
      });
      this.getListOfCoa(
        `parentAccount.code~*130*_AND_accountCurrency.secureId~*${value}*`,
        "init"
      );
      this.getListOfPreference(
        "search=key~*feature_based_currency*",
        code,
        code,
        true
      );
    }
  }
  getListOfMasterTypeInvoiceType() {
    let params = {
      name: "INVOICE_AR_TYPE",
    } as RequestQueryParamsModel;
    return masterServices.listMaster(params).then((res) => {
      this.dataListInvoiceType = res.filter((e) => e.value !== "Invoice AR");
      this.formTable.setFieldsValue({
        taxType: TAX_CALCULATION.NONE,
      });
      this.disabledTable.taxCode = true;
    });
  }
  print(): void {
    this.loading.print = true;
    invoicePrepayment.printInvoicePrepayment(this.$route.params.id)
    .then((dataBlob) => {
      if (dataBlob) {
        const url = window.URL.createObjectURL(new Blob([dataBlob]));
        printJs(url);
      }
    }).finally(() => this.loading.print = false);
  }
  changeCustomer(value, changeDropdown) {
    this.onchangeFormMandatory();
    if (value) {
      if (changeDropdown) {
        this.getContactDataDetail(value, false);
      }
      this.disabled.tabpan = false;
      this.form.setFieldsValue({
        customerShipTo: "",
        customerBillTo: "",
      });
      this.dataListCustomerShipTo = [];
      this.dataListCustomerBillTo = [];
      this.loading.customerShipTo = true;
      this.loading.customerBillTo = true;
      const cust = this.dataListCustomer.find((item) => item.id === value);
      if (cust) {
        cust.addressDataList.forEach((element) => {
          if (element.shipTo) {
            this.loading.customerShipTo = false;
            this.dataListCustomerShipTo.push({
              id: `${element.address}, ${element.cityDistrict}, ${element.country}, ${element.postalCode}`,
              name: `${element.address}, ${element.cityDistrict}, ${element.country}, ${element.postalCode}`,
              idCardNumber: element.idCardNumber,
            });
          }
          if (element.billTo) {
            this.loading.customerBillTo = false;
            this.dataListCustomerBillTo.push({
              id: `${element.address}, ${element.cityDistrict}, ${element.country}, ${element.postalCode}`,
              name: `${element.address}, ${element.cityDistrict}, ${element.country}, ${element.postalCode}`,
              idCardNumber: element.idCardNumber,
            });
          }
          if (element.billTo && element.primaryBillTo) {
            this.form.setFieldsValue({
              customerBillTo: `${element.address}, ${element.cityDistrict}, ${element.country}, ${element.postalCode}`,
            });
          }
          if (element.shipTo && element.primaryShipTo) {
            this.form.setFieldsValue({
              customerShipTo: `${element.address}, ${element.cityDistrict}, ${element.country}, ${element.postalCode}`,
            });
          }
        });
      }
    } else {
      this.disabled.tabpan = true;
      this.formTable.setFieldsValue({
        taxRegistrationNumber: "",
        customerShipTo: "",
        customerBillTo: "",
        // salesOrder: "",
      });
    }
  }
  searchTaxCode(value: string): void {
    this.getListOfTax(
      `taxType~VAT_OUT_AND_description~*${value}*`,
      value
    );
  }
  getListTaxCode() {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loadingTable.customerTax = true;
    settingsServices
      .listOfTaxInvoiceCode(params)
      .then((res) => {
        this.dataListTaxInvoicecode = res.data.filter(
          (e) => !e.revisionCode
        );
      })
      .finally(() => (this.loadingTable.customerTax = false));
  }
  detailStatus(value: "prepaymentUsed" | "prepaymentPaid"): void {
    this.columnsTableModal = [];
    const columnPrepaymentUsed = [
      {
        title: this.$t("lbl_invoice_date").toString(),
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        width: 200,
        scopedSlots: { customRender: "isNull" },
      },
      {
        title: this.$t("lbl_invoice_number").toString(),
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        width: 200,
        scopedSlots: { customRender: "isNull" },
      },
      {
        title: this.$t("lbl_prepayment_used_amount").toString(),
        dataIndex: "appliedAmount",
        key: "appliedAmount",
        width: 200,
        scopedSlots: { customRender: "isNull" },
      },
    ];
    const columnPrepaymentPaid = [
      {
        title: this.$t("lbl_ar_receipt_date").toString(),
        dataIndex: "receiptDate",
        key: "arReceiptDate",
        width: 200,
        scopedSlots: { customRender: "isNull" },
      },
      {
        title: this.$t("lbl_ar_receipt_number").toString(),
        dataIndex: "receiptNumber",
        key: "arReceiptNumber",
        width: 200,
        scopedSlots: { customRender: "isNull" },
      },
      {
        title: this.$t("lbl_amount").toString(),
        dataIndex: "receiptAmount",
        key: "amount",
        width: 200,
        scopedSlots: { customRender: "isNull" },
      },
    ];
    const dataAppendUsed = this.dataDetailStatus.invoiceARDetails.map(
      (item) => {
        return {
          appliedAmount: currencyFormat(item.appliedAmount.toString()),
          invoiceDate: moment(item.invoiceDate).format(DEFAULT_DATE_FORMAT),
          invoiceNumber: item.invoiceNumber,
        };
      }
    );
    const dataAppendPaid = this.dataDetailStatus.invoiceARReceiptDetails.map(
      (item) => {
        return {
          receiptAmount: currencyFormat(item.receiptAmount.toString()),
          receiptDate: moment(item.receiptDate).format(DEFAULT_DATE_FORMAT),
          receiptNumber: item.receiptNumber,
        };
      }
    );
    switch (value) {
      case "prepaymentUsed":
        this.columnsTableModal = columnPrepaymentUsed;
        this.dataListItemsModal = dataAppendUsed;
        this.modalOpen = true;
        break;
      case "prepaymentPaid":
        this.columnsTableModal = columnPrepaymentPaid;
        this.dataListItemsModal = dataAppendPaid;
        this.modalOpen = true;
        break;
      default:
        break;
    }
  }
  onBlur(value, form): void {
    if (value.target.value && !this.formTable.getFieldError(`${form}`)) {
      let dataobj = {
        taxRegistrationNumber: this.formTable.getFieldValue("taxRegistrationNumber"),
        taxRegistrationName: this.formTable.getFieldValue("taxRegistrationName"),
      };
      if (form === "taxRegistrationNumber") {
        dataobj.taxRegistrationNumber = this.formatTax(value.target.value);
      } else {
        dataobj.taxRegistrationName = value.target.value;
      }
      this.formTable.setFieldsValue(dataobj);
    }
    this.onchangeFormMandatory();
  }
  onFocus(value, form): void {
    if (value.target.value && !this.formTable.getFieldError(`${form}`)) {
      let dataobj = {
        taxRegistrationNumber: this.formTable.getFieldValue("taxRegistrationNumber"),
        taxRegistrationName: this.formTable.getFieldValue("taxRegistrationName"),
      };
      if (form === "taxRegistrationNumber") {
        dataobj.taxRegistrationNumber = this.formatTaxtoNumber(value.target.value);
      } else {
        dataobj.taxRegistrationName = value.target.value;
      }
      this.formTable.setFieldsValue(dataobj);
    }
  }
  checkDropdown(value, detail): void {
    if (
      this.dataListCustomer.findIndex((item) => item.id == value.customerId) ==
      -1
    ) {
      this.dataListCustomer.push({
        id: value.customerId,
        firstName: value.customerName,
        addressDataList: [
          {
            address: value.customerShipToAddress,
            shipTo: true,
            billTo: false,
          },
          {
            address: value.customerBillToAddress,
            shipTo: false,
            billTo: true,
          },
        ],
      });
    }
    if (
      this.dataListBranch.findIndex(
        (item) => item.id == value.branchWarehouseId
      ) == -1 &&
      value.branchWarehouseId
    ) {
      this.dataListBranch.push({
        code: "",
        createdDate: "",
        id: value.branchWarehouseId,
        modifiedDate: "",
        name: value.branchWarehouse,
        address: "",
      });
    }
    if (
      this.dataListCurrency.findIndex(
        (item) => item.currencyCode == value.currency
      ) == -1
    ) {
      this.dataListCurrency.push({
        id: "",
        currencyCode: value.currency,
        description: "",
      });
    }
    if (detail)
      if (
        this.dataListReceivableAccount.findIndex(
          (item) => item.id == value.receivableAccountId
        ) == -1
      ) {
        this.dataListReceivableAccount.push({
          balance: 0,
          code: value.receivableAccount.split(" - ")[0],
          createdDate: "",
          description: value.receivableAccount.split(" - ")[1],
          id: value.receivableAccountId,
          modifiedDate: "",
          parentId: "",
        });
      }
  }
  getListOfPreference(query, currency, fromCurrency, receivableAccount) {
    let params = {
      limit: 10,
      page: 0,
      name: query,
    } as RequestQueryParamsModel;
    this.loading.receivableAccount = true;
    return settingsServices
      .getPreference(params)
      .then((res: any) => {
        if (!res.length) return;
        if (receivableAccount) {
          if (currency === "IDR") {
            this.getDetailListOfCoa(res[0].value, receivableAccount);
          }
          else {
            this.getListOfCurrency(res[0].value, fromCurrency);
          }
        } else {
          this.getDetailListOfCoa(res[0].value, false);
        }
        if (receivableAccount === "null") {
          if (res) {
            this.form.setFieldsValue({
              currency: res[0].value,
            });
            this.changeCurrency(res[0].value);
          }
        }
      })
      .finally(() => (this.loading.receivableAccount = false));
  }
  getListOfCurrency(toCurrency, fromCurrency) {
    let params = {
      limit: 10,
      page: 0,
      search: `fromCurrency.currencyCode~${fromCurrency}_AND_toCurrency.secureId~${toCurrency}`,
    } as RequestQueryParamsModel;
    return settingsServices.listOfCurrency(params, "")
    .then((res: any) => {
      if (res.data.length > 0) {
        this.disabled.rate = true;
        this.form.setFieldsValue({
          rate: currencyFormat(res.data[0].rate),
        });
        this.currencyRate = res.data[0].rate;
      } else {
        this.disabled.rate = false;
        this.form.setFieldsValue({
          rate: 0,
        });
      }
      this.checkMandatory++;
    });
  }
  getTaxInvoiceNumber(path: string): void {
    invoicePrepayment.getInvoicePrepaymentCode(path).then((res) => {
      this.formTable.setFieldsValue({
        taxInvoiceNumber: res,
      });
    });
  }
  getDetailListOfCoa(path: string, receivableAccount) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.receivableAccount = true;
    settingsServices
      .listOfCoa(params, `/${path}`)
      .then((res: any) => {
        if (receivableAccount) {
          this.dataListReceivableAccount.push(res);
          this.form.setFieldsValue({
            receivableAccount: res.id,
          });
          this.disabled.receivableAccount = true;
        } else {
          this.formTable.setFieldsValue({
            lineAccount: `${res.code} - ${res.description}`,
          });
        }
      })
      .finally(() => (this.loading.receivableAccount = false));
  }
  changeRate(value): void {
    if (value.target.value)
      this.form.setFieldsValue({
        rate: currencyFormat(value.target.value),
      });
    this.checkOnBlur += 1;
    this.currencyRate = changeCurrencytoNumeric(value.target.value);
  }
  getListOfCoa(query, value): void {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    if (query && value) params.search = query;
    this.loading.receivableAccount = true;
    settingsServices
      .listOfCoa(params, "")
      .then((res: any) => {
        this.dataListReceivableAccount = res.data;
        if (res.data.length > 0) {
          this.form.setFieldsValue({
            receivableAccount: res.data[0].id,
          });
        } else {
          this.form.setFieldsValue({
            receivableAccount: "",
          });
        }
        this.disabled.receivableAccount = false;
      })
      .finally(() => (this.loading.receivableAccount = false));
  }
  getListOfWarehouseBranch(query, value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (query && value) params.search = query;
    this.loading.branch = true;
    return logisticServices
      .listWarehouseBranch(params, "")
      .then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index));
        this.dataListBranch = res.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  getListOfMasterType() {
    let params = {
      name: "TAX_CALCULATION",
    } as RequestQueryParamsModel;
    return masterServices.listMaster(params).then((res) => {
      this.dataListTaxType = res;
    });
  }
  handleChangeMenu(value: string): void {
    this.activeTabPane = value;
  }
  handleCancel(): void {
    this.modalOpen = false;
  }
  back(): void {
    this.$router.push("/account-receivables/invoices/invoiceprepayment");
  }
  saveItems(type: "cancel" | "submit"): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        this.formTable.validateFields((errTable, valueTable) => {
          if (!errTable) {
            this.formTotal.validateFields((errTableTotal, valueTableTotal) => {
              if (!errTableTotal) {
                const dataPost = {} as CreateInvoicePrepayment;
                dataPost.accountingDate = value.accountingDate;
                dataPost.amount = changeCurrencytoNumeric(valueTable.amount);
                dataPost.currencyId = value.currency;
                dataPost.currencyRate = value.rate
                  ? changeCurrencytoNumeric(value.rate)
                  : 0;
                dataPost.customerTaxType = valueTable.customerTaxType;
                dataPost.description = value.invoiceDescription;
                dataPost.invoiceDate = moment(value.invoiceDate).format();
                dataPost.invoiceType = value.invoiceType;
                dataPost.operatorName = value.operatorName;
                dataPost.poNumber = valueTable.poNumber;
                dataPost.receivableAccountId = value.receivableAccount;
                // dataPost.salesOrderId = value.salesOrder;
                dataPost.branchId = value.branch;
                dataPost.customerId = value.customerName;
                dataPost.customerShipToAddress = value.customerShipTo;
                dataPost.customerBillToAddress = value.customerBillTo;
                dataPost.taxInvoiceDate = moment(
                  valueTable.taxInvoiceDate
                ).format();
                dataPost.taxInvoiceNumber = valueTable.taxInvoiceNumber;
                dataPost.taxRegistrationNumber = valueTable.taxRegistrationNumber;
                dataPost.taxRegistrationName = valueTable.taxRegistrationName || "";
                dataPost.taxType = valueTable.taxType;
                dataPost.termOfPayment = Number(valueTable.termOfPayment);
                dataPost.subTotal = changeCurrencytoNumeric(
                  valueTableTotal.subTotal
                );
                dataPost.totalTax = changeCurrencytoNumeric(
                  valueTableTotal.totalTax
                );
                dataPost.taxId = valueTable.taxCode;
                dataPost.total = changeCurrencytoNumeric(valueTableTotal.total);
                if (type == "submit") {
                  this.loading.submit = true;
                  invoicePrepayment
                    .createInvoicePrepayment(dataPost)
                    .then((res) => {
                      this.disabled.print = false;
                      this.$notification.success({
                        description: res["documentNumber"],
                        message: this.$t("lbl_success").toString(),
                      });

                      this.$router.push(
                        "/account-receivables/invoices/invoiceprepayment"
                      );
                    })
                    .catch((e) => {
                      this.$notification.error({
                        description: e.details,
                        message: this.$t("lbl_error_title").toString(),
                      });
                      this.showNotifFailCreate();

                      this.disabled.cancel = true;
                      this.disabled.submit = false;
                    })
                    .finally(() => {
                      this.loading.submit = false;
                      // this.disabled.cancel = false;
                      // this.disabled.submit = true;
                      // this.disabled.print = true;
                    });
                } else {
                  this.loading.cancel = true;
                  invoicePrepayment
                    .cancelInvoicePrepayment(this.$route.params.id)
                    .then((res) => {
                      this.$notification.success({
                        description: res["documentNumber"],
                        message: this.$t("lbl_success").toString(),
                      });
                      this.disabled.print = false;
                      this.$router.push(
                        "/account-receivables/invoices/invoiceprepayment"
                      );
                    })
                    .catch((e) => {
                      this.$notification.error({
                        description: e.details,
                        message: this.$t("lbl_error_title").toString(),
                      });
                      this.disabled.cancel = false;
                    })
                    .finally(() => {
                      this.loading.cancel = false;
                      // this.disabled.cancel = true;
                      // this.disabled.submit = true;
                      // this.disabled.print = true;
                    });
                }
              } else {
                this.showNotifValidationError(this.$t("lbl_invalid_form_total"));
              }
            });
          } else {
            this.showNotifValidationError(this.$t("lbl_invalid_form_tab_detail_and_tax_detail"));
          }
        });
      } else {
        this.showNotifValidationError(this.$t("lbl_invalid_form_value"));
      }
    });
  }
  getListOfMasterCurrency(query, value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (query && value) params.search = query;
    return settingsServices
      .listOfMasterCurrency(params, "")
      .then((res) => {
        this.dataListCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }

  getListCustomer(query: string, value: string) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "customer~true_AND_active~true",
      sorts: "firstName:asc"
    };
    if (query && value) params.search += query;
    this.loadingCustomer = true;
    return contactServices
      .listContactData(params)
      .then((response) => (this.dataListCustomer = response.data))
      .finally(() => (this.loadingCustomer = false));
  }
  getContactDataDetail(id, changeSo) {
    return contactServices.getContactData(id).then((res) => {
      if (changeSo) {
        this.form.setFieldsValue({
          customerName: res.id,
        });
        res.addressDataList.forEach((element) => {
          if (element.shipTo) {
            this.form.setFieldsValue({
              customerShipTo: element.address,
            });
          }
          if (element.billTo) {
            this.form.setFieldsValue({
              customerBillTo: element.address,
            });
          }
        });
      }
      if (res.taxRegisNumber == " " || res.taxRegisNumber == "-") {
        res.addressDataList.forEach((element) => {
          if (element.billTo) {
            if (element.idCardNumber == " " || element.idCardNumber == "-") {
              this.disabledTable.taxRegistrationNumber = false;
              this.formTable.setFieldsValue({
                taxRegistrationNumber: "",
              });
            } else {
              this.formTable.setFieldsValue({
                taxRegistrationNumber: element.idCardNumber,
              });
              this.disabledTable.taxRegistrationNumber = true;
            }
          }
        });
      } else {
        this.formTable.setFieldsValue({
          taxRegistrationNumber: res.taxRegisNumber,
        });
        this.disabledTable.taxRegistrationNumber = true;
      }
      if (res.taxRegisName == " " || res.taxRegisName == "-") {
        this.formTable.setFieldsValue({
          taxRegistrationName: "",
        });
        this.disabledTable.taxRegistrationName = false;
      } else {
        this.disabledTable.taxRegistrationName = true;
        this.formTable.setFieldsValue({
          taxRegistrationName: res.taxRegisName,
        });
      }
      if (res.customerData.taxId) {
        this.formTable.setFieldsValue({
          customerTaxType: res.customerData.taxId,
        });
        this.getTaxInvoiceNumber(res.customerData.taxId);
        
      } else {
        this.formTable.setFieldsValue({
          customerTaxType: "",
          taxInvoiceNumber: "",
        });
      }
      
      if (res.top != null || res.top != "") {
        this.formTable.setFieldsValue({
          termOfPayment: res.top.toString(),
        });
      }
    });
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  handleCancelModal(): void {
    this.modalOpen = false;
  }
}
