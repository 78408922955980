var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title:
              _vm.mode === "create"
                ? _vm.$t("lbl_invoice_prepayment_credit_memo")
                : _vm.$t("lbl_invoice_prepayment_credit_memo_edit")
          }
        },
        [
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form",
                        { attrs: { layout: "vertical", form: _vm.form } },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16], type: "flex" } },
                            [
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.invoiceType.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "invoiceType",
                                                {
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "[\n                      'invoiceType',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: $t('lbl_validation_required_error'),\n                          },\n                        ],\n                      }]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.invoiceType.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.invoiceType
                                                .placeholder
                                            ),
                                            disabled: _vm.disabled.invoiceType,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.invoiceType,
                                            "allow-clear": true
                                          },
                                          on: {
                                            change: _vm.onchangeFormMandatory
                                          }
                                        },
                                        _vm._l(
                                          _vm.dataListInvoiceType,
                                          function(data, index) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: index,
                                                attrs: { value: data.value }
                                              },
                                              [
                                                _c(
                                                  "a-tooltip",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "title" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "" + data.value
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "" + data.value
                                                        ) +
                                                        " "
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.currency.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "currency",
                                                {
                                                  initialValue: undefined,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "\n                      [\n                        'currency',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            name: _vm.formRules.currency.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.currency.placeholder
                                            ),
                                            disabled: _vm.disabled.currency,
                                            "show-search": true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.currency,
                                            "allow-clear": true
                                          },
                                          on: {
                                            change: function(value) {
                                              return _vm.changeCurrency(value)
                                            },
                                            search: function(value) {
                                              return _vm.getListOfMasterCurrency(
                                                "currencyCode~*" + value + "*",
                                                value
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.dataListCurrency, function(
                                          data
                                        ) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: data.id,
                                              attrs: { value: data.id }
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            "" +
                                                              data.currencyCode
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "" + data.currencyCode
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.typeCurrency && _vm.typeCurrency !== "IDR"
                                ? _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 8 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.rate.label
                                            )
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "rate",
                                                  {
                                                    initialValue: undefined,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: _vm.$t(
                                                          "lbl_validation_required_error"
                                                        )
                                                      }
                                                    ]
                                                  }
                                                ],
                                                expression:
                                                  "\n                      [\n                        'rate',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                              }
                                            ],
                                            attrs: {
                                              name: _vm.formRules.rate.name,
                                              placeholder: _vm.$t(
                                                _vm.formRules.rate.placeholder
                                              ),
                                              autocomplete: "off",
                                              disabled: _vm.disabled.rate
                                            },
                                            on: {
                                              change: _vm.onchangeFormMandatory,
                                              blur: function(value) {
                                                return _vm.changeRate(value)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.branch.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "branch",
                                                {
                                                  initialValue: undefined,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "\n                      [\n                        'branch',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            disabled: _vm.disabled.branch,
                                            name: _vm.formRules.branch.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.branch.placeholder
                                            ),
                                            "show-search": true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.branch,
                                            "allow-clear": true
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getListOfWarehouseBranch(
                                                "name~*" + value + "*",
                                                value
                                              )
                                            },
                                            change: _vm.onchangeFormMandatory
                                          }
                                        },
                                        _vm._l(_vm.dataListBranch, function(
                                          data
                                        ) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: data.id,
                                              attrs: { value: data.id }
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.name || "-"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.name || "-") +
                                                      " "
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.customerName.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "customerName",
                                                {
                                                  initialValue: undefined,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "\n                      [\n                        'customerName',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.customerName.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.customerName
                                                .placeholder
                                            ),
                                            disabled: _vm.disabled.customerName,
                                            "show-search": true,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.customerName,
                                            "allow-clear": true
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getListCustomer(
                                                "_AND_firstName~*" +
                                                  value +
                                                  "*_OR_lastName~*" +
                                                  value +
                                                  "*",
                                                value
                                              )
                                            },
                                            change: function(value) {
                                              return _vm.changeCustomer(
                                                value,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(_vm.dataListCustomer, function(
                                          data
                                        ) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: data.id,
                                              attrs: { value: data.id }
                                            },
                                            [
                                              _c(
                                                "a-tooltip",
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.firstName ||
                                                              "-"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.firstName || "-"
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.receivableAccount.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "receivableAccount",
                                                {
                                                  initialValue: undefined,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "\n                      [\n                        'receivableAccount',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.receivableAccount
                                                .name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.receivableAccount
                                                .placeholder
                                            ),
                                            disabled:
                                              _vm.disabled.receivableAccount,
                                            "option-filter-prop": "children",
                                            "show-search": true,
                                            "filter-option": _vm.filterOption,
                                            loading:
                                              _vm.loading.receivableAccount,
                                            "allow-clear": true
                                          },
                                          on: {
                                            search: function(value) {
                                              return _vm.getListOfCoa(
                                                "code~*" +
                                                  value +
                                                  "*_OR_description~*" +
                                                  value +
                                                  "*",
                                                value
                                              )
                                            },
                                            change: _vm.onchangeFormMandatory
                                          }
                                        },
                                        _vm._l(
                                          _vm.dataListReceivableAccount,
                                          function(data, index) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: index,
                                                attrs: { value: data.id }
                                              },
                                              [
                                                _c(
                                                  "a-tooltip",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "title" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.code +
                                                                " - " +
                                                                data.description
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.code +
                                                            " - " +
                                                            data.description
                                                        ) +
                                                        " "
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.invoiceDate.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-date-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "invoiceDate",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: _vm.$t(
                                                      "lbl_validation_required_error"
                                                    )
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "\n                      [\n                        'invoiceDate',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                          }
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            _vm.formRules.invoiceDate
                                              .placeholder
                                          ),
                                          format: _vm.DEFAULT_DATE_FORMAT,
                                          disabled: _vm.disabled.invoiceDate
                                        },
                                        on: {
                                          change: function(value) {
                                            return _vm.changeInvoiceDate(value)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.customerShipTo.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "customerShipTo",
                                                {
                                                  initialValue: undefined,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "\n                      [\n                        'customerShipTo',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.customerShipTo.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.customerShipTo
                                                .placeholder
                                            ),
                                            disabled:
                                              _vm.disabled.customerShipTo,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.customerShipTo,
                                            "allow-clear": true
                                          },
                                          on: {
                                            change: _vm.onchangeFormMandatory
                                          }
                                        },
                                        _vm._l(
                                          _vm.dataListCustomerShipTo,
                                          function(data, index) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: index,
                                                attrs: { value: data.id }
                                              },
                                              [
                                                _c(
                                                  "a-tooltip",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "title" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "" + data.name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s("" + data.name) +
                                                        " "
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.customerBillTo.label
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "customerBillTo",
                                                {
                                                  initialValue: undefined,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: _vm.$t(
                                                        "lbl_validation_required_error"
                                                      )
                                                    }
                                                  ]
                                                }
                                              ],
                                              expression:
                                                "\n                      [\n                        'customerBillTo',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              _vm.formRules.customerBillTo.name,
                                            placeholder: _vm.$t(
                                              _vm.formRules.customerBillTo
                                                .placeholder
                                            ),
                                            disabled:
                                              _vm.disabled.customerBillTo,
                                            "option-filter-prop": "children",
                                            "filter-option": _vm.filterOption,
                                            loading: _vm.loading.customerBillTo,
                                            "allow-clear": true
                                          },
                                          on: {
                                            change: _vm.onchangeFormMandatory
                                          }
                                        },
                                        _vm._l(
                                          _vm.dataListCustomerBillTo,
                                          function(data, index) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: index,
                                                attrs: { value: data.id }
                                              },
                                              [
                                                _c(
                                                  "a-tooltip",
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "title" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "" + data.name
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s("" + data.name) +
                                                        " "
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.accountingDate.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-date-picker", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "accountingDate",
                                              {
                                                initialValue: undefined,
                                                rules: [
                                                  {
                                                    required: true,
                                                    message: _vm.$t(
                                                      "lbl_validation_required_error"
                                                    )
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "\n                      [\n                        'accountingDate',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: true,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                          }
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            _vm.formRules.accountingDate
                                              .placeholder
                                          ),
                                          format: _vm.DEFAULT_DATE_FORMAT,
                                          disabled: true
                                        },
                                        on: {
                                          change: _vm.onchangeFormMandatory
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.operatorName.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "operatorName",
                                              {
                                                initialValue: undefined,
                                                rules: [
                                                  {
                                                    required: false,
                                                    message: _vm.$t(
                                                      "lbl_validation_required_error"
                                                    )
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "\n                      [\n                        'operatorName',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: false,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.operatorName.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.operatorName
                                              .placeholder
                                          ),
                                          autocomplete: "off",
                                          disabled: _vm.disabled.operatorName
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { sm: 24, md: 12, lg: 8 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.invoiceDescription.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-textarea", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "invoiceDescription",
                                              {
                                                initialValue: undefined,
                                                rules: [
                                                  {
                                                    required: false,
                                                    message: _vm.$t(
                                                      "lbl_validation_required_error"
                                                    )
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "\n                      [\n                        'invoiceDescription',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: false,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                          }
                                        ],
                                        attrs: {
                                          name:
                                            _vm.formRules.invoiceDescription
                                              .name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.invoiceDescription
                                              .placeholder
                                          ),
                                          autocomplete: "off",
                                          disabled:
                                            _vm.disabled.invoiceDescription,
                                          "auto-size": { maxRows: 4 }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.mode !== "create"
                                ? _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 8 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.invoiceNumber.label
                                            )
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "invoiceNumber",
                                                  {
                                                    initialValue: undefined,
                                                    rules: [
                                                      {
                                                        required: false,
                                                        message: _vm.$t(
                                                          "lbl_validation_required_error"
                                                        )
                                                      }
                                                    ]
                                                  }
                                                ],
                                                expression:
                                                  "\n                      [\n                        'invoiceNumber',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: false,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                              }
                                            ],
                                            attrs: {
                                              name:
                                                _vm.formRules.invoiceNumber
                                                  .name,
                                              placeholder: _vm.$t(
                                                _vm.formRules.invoiceNumber
                                                  .placeholder
                                              ),
                                              autocomplete: "off",
                                              disabled:
                                                _vm.disabled.invoiceNumber
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mode !== "create" && _vm.show.returnNumber
                                ? _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 8 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.returnNumber.label
                                            )
                                          }
                                        },
                                        [
                                          _c("a-input", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "returnNumber",
                                                  {
                                                    initialValue: undefined,
                                                    rules: [
                                                      {
                                                        required: false,
                                                        message: _vm.$t(
                                                          "lbl_validation_required_error"
                                                        )
                                                      }
                                                    ]
                                                  }
                                                ],
                                                expression:
                                                  "\n                      [\n                        'returnNumber',\n                        {\n                          initialValue: undefined,\n                          rules: [\n                            {\n                              required: false,\n                              message: $t('lbl_validation_required_error'),\n                            },\n                          ],\n                        },\n                      ]\n                    "
                                              }
                                            ],
                                            attrs: {
                                              name:
                                                _vm.formRules.returnNumber.name,
                                              placeholder: _vm.$t(
                                                _vm.formRules.returnNumber
                                                  .placeholder
                                              ),
                                              autocomplete: "off",
                                              disabled:
                                                _vm.disabled.returnNumber
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mode !== "create"
                                ? _c(
                                    "a-col",
                                    { attrs: { sm: 24, md: 12, lg: 8 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              _vm.formRules.journalNumber.label
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "dashed" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clickJournal(
                                                    _vm.journalNumber.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.journalNumber.name ||
                                                      "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("a-col", { attrs: { span: 12 } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "mt-2", attrs: { span: 24 } },
                    [
                      _c(
                        "a-tabs",
                        {
                          attrs: {
                            "default-active-key": _vm.activeTabPane,
                            "active-key": _vm.activeTabPane,
                            type: "card"
                          },
                          on: { change: _vm.handleChangeMenu }
                        },
                        _vm._l(_vm.columnTabPane, function(item) {
                          return _c(
                            "a-tab-pane",
                            {
                              key: item,
                              attrs: { disabled: _vm.disabled.tabpan }
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "tab" }, slot: "tab" },
                                [
                                  _vm._v(" " + _vm._s(item) + " "),
                                  _c("a-icon", {
                                    staticStyle: { color: "#FF5C58" },
                                    attrs: { type: "tag" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    staticClass: "px-3",
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.formTable
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.activeTabPane === "Details",
                                          expression:
                                            "activeTabPane === 'Details'"
                                        }
                                      ],
                                      staticClass: "pt-4"
                                    },
                                    [
                                      _c("TableDetails", {
                                        attrs: {
                                          "loading-table": _vm.loadingTable,
                                          "disabled-table": _vm.disabledTable,
                                          "onchange-form-mandatory":
                                            _vm.onchangeFormMandatory,
                                          "change-amount": _vm.changeAmount
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.activeTabPane === "Tax Details",
                                          expression:
                                            "activeTabPane === 'Tax Details'"
                                        }
                                      ],
                                      staticClass: "pt-4"
                                    },
                                    [
                                      _c("TableTaxDetails", {
                                        attrs: {
                                          "loading-table": _vm.loadingTable,
                                          "disabled-table": _vm.disabledTable,
                                          "data-list-tax-type":
                                            _vm.dataListTaxType,
                                          "data-list-tax-invoicecode":
                                            _vm.dataListTaxInvoicecode,
                                          "change-customer-tax":
                                            _vm.changeCustomerTax,
                                          "data-list-taxcode":
                                            _vm.dataListTaxcode,
                                          "search-tax-code": _vm.searchTaxCode,
                                          "onchange-form-mandatory":
                                            _vm.onchangeFormMandatory,
                                          "on-blur": _vm.onBlur,
                                          "on-focus": _vm.onFocus
                                        },
                                        on: {
                                          "change-tax-code": _vm.changeTaxCode,
                                          "on-change": _vm.changeTaxType
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.activeTabPane === "Status",
                                          expression:
                                            "activeTabPane === 'Status'"
                                        }
                                      ],
                                      staticClass: "pt-4"
                                    },
                                    [
                                      _c("TableStatus", {
                                        attrs: {
                                          "detail-status": _vm.detailStatus
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16], type: "flex" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                {
                                  attrs: {
                                    layout: "vertical",
                                    form: _vm.formTotal
                                  }
                                },
                                [
                                  _c(
                                    "a-row",
                                    {
                                      attrs: { gutter: [16, 16], type: "flex" }
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.subTotal.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: [
                                                      "subTotal",
                                                      {
                                                        initialValue: undefined,
                                                        rules: [
                                                          {
                                                            required: false,
                                                            message: _vm.$t(
                                                              "lbl_validation_required_error"
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    expression:
                                                      "\n                          [\n                            'subTotal',\n                            {\n                              initialValue: undefined,\n                              rules: [\n                                {\n                                  required: false,\n                                  message: $t('lbl_validation_required_error'),\n                                },\n                              ],\n                            },\n                          ]\n                        "
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.subTotal.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.subTotal
                                                      .placeholder
                                                  ),
                                                  autocomplete: "off",
                                                  "read-only": true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.totalTax.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: [
                                                      "totalTax",
                                                      {
                                                        initialValue: undefined,
                                                        rules: [
                                                          {
                                                            required: false,
                                                            message: _vm.$t(
                                                              "lbl_validation_required_error"
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    expression:
                                                      "\n                          [\n                            'totalTax',\n                            {\n                              initialValue: undefined,\n                              rules: [\n                                {\n                                  required: false,\n                                  message: $t('lbl_validation_required_error'),\n                                },\n                              ],\n                            },\n                          ]\n                        "
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.totalTax.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.totalTax
                                                      .placeholder
                                                  ),
                                                  autocomplete: "off",
                                                  "read-only": true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.total.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value: [
                                                      "total",
                                                      {
                                                        initialValue: undefined,
                                                        rules: [
                                                          {
                                                            required: false,
                                                            message: _vm.$t(
                                                              "lbl_validation_required_error"
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    ],
                                                    expression:
                                                      "\n                          [\n                            'total',\n                            {\n                              initialValue: undefined,\n                              rules: [\n                                {\n                                  required: false,\n                                  message: $t('lbl_validation_required_error'),\n                                },\n                              ],\n                            },\n                          ]\n                        "
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRules.total.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.total
                                                      .placeholder
                                                  ),
                                                  autocomplete: "off",
                                                  "read-only": true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-card-grid",
            { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
            [
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.back } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.hasPrivilegeUpdate
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                loading: _vm.loading.cancel,
                                disabled: _vm.disabled.cancel
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveItems("cancel")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                          )
                        : _vm._e(),
                      _vm.hasPrivilegeUpdate
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "printer",
                                loading: _vm.loading.print,
                                disabled: _vm.disabled.print
                              },
                              on: {
                                click: function($event) {
                                  return _vm.print()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      _vm.hasPrivilegeUpdate
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.submit,
                                disabled: _vm.disabled.submit,
                                icon: "save"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveItems("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            "mask-closable": false,
            width: 750,
            title: _vm.$t("lbl_details"),
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-card",
                [
                  _c(
                    "a-row",
                    {
                      attrs: {
                        justify: "space-between",
                        type: "flex",
                        gutter: [16, 16]
                      }
                    },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lbl_total_found")) +
                                " : " +
                                _vm._s(_vm.dataListItemsModal.length) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table2",
                              "data-source": _vm.dataListItemsModal,
                              columns: _vm.columnsTableModal,
                              scroll: { x: "calc(350px + 50%)" },
                              paginationcustom: false,
                              "default-pagination": true
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.handleCancelModal } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }